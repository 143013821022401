<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- Date Picker inside v-menu -->
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" max-width="330" min-width="330">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field filled hide-details="" v-model="dateRange" label="Seleccionar un rango de fechas" readonly
              v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar"></v-text-field>
          </template>
          <v-date-picker v-model="dateRange" range width="100%" scrollable
            :min="dateRange.length > 0 ? dateRange[0] : null">
            <v-spacer></v-spacer>
            <v-btn text color="primary darken-1" @click="dateRange = []; menu = false">Cancelar</v-btn>
            <v-btn text color="primary darken-1" @click="menu = false; fetchData()">Confirmar</v-btn>
          </v-date-picker>
        </v-menu>

        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <apexchart ref="chart" v-if="chartData.length > 0" type="bar" :options="chartOptions" :series="chartData">
        </apexchart>
        <v-skeleton-loader v-else-if="loading" type="image"></v-skeleton-loader>
        <v-alert v-else-if="dateRange.length < 2" type="info" color="primary darken-3">
          Selecciona un rango de fechas para ver las estadísticas.
        </v-alert>
        <v-alert v-else type="info" color="primary darken-3">
          No hay datos para mostrar en el rango de fechas seleccionado.
        </v-alert>
      </v-col>

      <v-col cols="12" md="6">
        <apexchart v-if="dailyChartData.length > 0" type="bar" :options="dailyChartOptions" :series="dailyChartData">
        </apexchart>
        <v-skeleton-loader v-else-if="loading" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="chartData.length > 0">
      <v-col cols="12" lg="8" offset-lg="2">
        <v-card>
          <v-card-title>Utilizadas por día (%)</v-card-title>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <!-- show nombre only if stat.class has different values and not all equal-->
                  <th
                    v-if="statistics.length > 1 && statistics.some((stat, index) => stat.class !== statistics[0].class)">
                    Nombre de clase</th>
                  <th>Día de la Semana</th>
                  <th>Promedio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(stat, index) in statistics" :key="index">
                  <td
                    v-if="statistics.length > 1 && statistics.some((stat, index) => stat.class !== statistics[0].class)">
                    {{ stat.class }}</td>
                  <td>{{ stat.day }}</td>
                  <td>{{ stat.mean.toFixed(2) * 100 }}%</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      loading: false,
      menu: false,
      dateRange: [],
      chartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          id: 'class-stats',
          stacked: true,
          type: 'bar',
          toolbar: {
            show: true
          }
        },
        xaxis: {
          categories: [],
          labels: {
            rotate: -45,
            trim: false,
            style: {
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          title: {
            text: 'Cantidad'
          },
          min: 0
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
            dataLabels: {
              position: 'center'
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ["#fff"]
          },
          formatter: function (val) {
            return val || '';
          },
          dropShadow: {
            enabled: false
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
        },
        tooltip: {
          shared: true,
          intersect: false
        }
      },
      chartData: [],
      dailyChartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          id: 'daily-class-stats',
          stacked: true
        },
        xaxis: {
          categories: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
        },
        yaxis: {
          title: {
            text: 'Cantidad'
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'center'
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ["#fff"]
          },
          dropShadow: {
            enabled: false
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
        }
      },
      dailyChartData: [],
      statistics: []
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let start, end;

      if (this.dateRange.length == 2) {
        start = new Date(this.dateRange[0] + " 00:00:00").toISOString().split('T')[0];
        end = new Date(this.dateRange[1] + " 23:59:59");
        end.setHours(23, 59, 59, 999);
        end = end.toISOString().split('T')[0];
      } else {
        // here start is the start of the month and end is today at 23:59:59
        let date = moment()
        let startOfMonth = moment().startOf('month')

        start = moment(new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")).format('YYYY-MM-DD')
        end = moment(new Date(date.format('YYYY-MM-DD') + " 23:59:59")).format('YYYY-MM-DD')

        this.dateRange = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
      }

      const db = getFirestore();
      const scheduleRef = collection(db, 'schedule');
      const scheduleQuery = query(
        scheduleRef,
        where('__name__', '>=', start),
        where('__name__', '<=', end)
      );
      const snapshot = await getDocs(scheduleQuery);

      const fetchPromises = snapshot.docs.map(async (doc) => {
        const schedulesRef = collection(doc.ref, 'schedules');
        return getDocs(schedulesRef);
      });

      const schedulesSnapshots = await Promise.all(fetchPromises);

      let classStats = {};
      let dailyStats = {};
      let weeklyStats = {};

      schedulesSnapshots.forEach((schedulesSnapshot, index) => {
        const doc = snapshot.docs[index];
        console.log('Processing document:', doc.id);

        schedulesSnapshot.forEach((classDoc) => {
          const classData = classDoc.data();
          const classType = classData.type;
          let time;
          let startTime;
          let dayOfWeek;

          // Standardize time handling
          if (classData.startDate && classData.startDate.seconds) {
            startTime = new Date(classData.startDate.seconds * 1000);
          } else {
            startTime = new Date(classData.startDate);
          }

          // Format time consistently using 24-hour format
          time = startTime.toLocaleTimeString('es-ES', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          });

          dayOfWeek = startTime.getDay();

          console.log('Processing class:', {
            date: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
            time,
            classType,
            dayOfWeek,
            users: classData.users.length
          });

          const dayString = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'][dayOfWeek];

          // Initialize stats objects
          if (!classStats[time]) {
            classStats[time] = {};
          }
          if (!classStats[time][classType]) {
            classStats[time][classType] = { checkedIn: 0, notAttended: 0, lateCheckedIn: 0 };
          }

          if (!dailyStats[dayString]) {
            dailyStats[dayString] = {};
          }
          if (!dailyStats[dayString][classType]) {
            dailyStats[dayString][classType] = { checkedIn: 0, notAttended: 0, lateCheckedIn: 0 };
          }

          if (!weeklyStats[classType]) {
            weeklyStats[classType] = {};
          }
          if (!weeklyStats[classType][dayString]) {
            weeklyStats[classType][dayString] = [];
          }

          // Process user check-ins
          classData.users.forEach(user => {
            if (user.checkedIn) {
              let checkInTime;
              if (typeof user.checkedIn === 'string') {
                checkInTime = new Date(user.checkedIn);
              } else if (user.checkedIn.seconds) {
                checkInTime = new Date(user.checkedIn.seconds * 1000);
              } else {
                console.warn('Unexpected checkedIn format:', user.checkedIn);
                return;
              }

              // Calculate time difference in minutes
              const timeDiffMinutes = (checkInTime - startTime) / (1000 * 60);
              console.log('Check-in time difference (minutes):', timeDiffMinutes);

              if (timeDiffMinutes > 10) {
                classStats[time][classType].lateCheckedIn++;
              } else {
                classStats[time][classType].checkedIn++;
              }

              dailyStats[dayString][classType].checkedIn++;
              weeklyStats[classType][dayString].push(1);
            } else {
              classStats[time][classType].notAttended++;
              dailyStats[dayString][classType].notAttended++;
              weeklyStats[classType][dayString].push(0);
            }
          });
        });
      });

      // After processing all schedules, add this debug log
      console.log('Final classStats:', JSON.stringify(classStats, null, 2));

      let seriesData = {
        checkedIn: [],
        notAttended: [],
        lateCheckedIn: []
      };

      let sortedTimes = Object.keys(classStats).sort((a, b) => {
        const getMinutes = (timeStr) => {
          const [hours, minutes] = timeStr.split(':').map(Number);
          return hours * 60 + minutes;
        };
        return getMinutes(a) - getMinutes(b);
      });

      console.log('Sorted times:', sortedTimes);

      // Create arrays to hold the categories and data points
      let categories = [];
      let checkedInData = [];
      let notAttendedData = [];
      let lateCheckedInData = [];

      // Process the data in sorted order
      for (const time of sortedTimes) {
        console.log(`Processing time ${time}:`, classStats[time]);

        for (const [type, stats] of Object.entries(classStats[time])) {
          const label = `${time} - ${type}`;

          // Only add categories and data if there's actual data
          if (stats.checkedIn > 0 || stats.notAttended > 0 || stats.lateCheckedIn > 0) {
            categories.push(label);
            checkedInData.push(stats.checkedIn);
            notAttendedData.push(stats.notAttended);
            lateCheckedInData.push(stats.lateCheckedIn);

            console.log(`Added data point for ${label}:`, {
              checkedIn: stats.checkedIn,
              notAttended: stats.notAttended,
              lateCheckedIn: stats.lateCheckedIn
            });
          }
        }
      }

      // Update chart options and data
      this.chartOptions.xaxis.categories = categories;

      this.chartData = [
        {
          name: 'Asistencias',
          data: checkedInData,
          color: '#2E7D32' // green
        },
        {
          name: 'Reserva sin uso',
          data: notAttendedData,
          color: '#C62828' // red
        },
        {
          name: 'Asistencias tardías',
          data: lateCheckedInData,
          color: '#F9A825' // yellow
        }
      ];

      console.log('Final chart data:', {
        categories: this.chartOptions.xaxis.categories,
        series: this.chartData
      });

      let dailySeriesData = {
        checkedIn: [],
        notAttended: [],
        lateCheckedIn: []
      };

      for (let day of ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']) {
        for (const [type, stats] of Object.entries(dailyStats[day] || {})) {
          dailySeriesData.checkedIn.push({
            x: `${type} - ${day}`,
            y: stats.checkedIn
          });
          dailySeriesData.notAttended.push({
            x: `${type} - ${day}`,
            y: stats.notAttended
          });
          dailySeriesData.lateCheckedIn.push({
            x: `${type} - ${day}`,
            y: stats.lateCheckedIn
          });
        }
      }

      this.dailyChartData = [
        { name: 'Asistencias', data: dailySeriesData.checkedIn },
        { name: 'Reserva sin uso', data: dailySeriesData.notAttended },
      ];

      this.statistics = [];
      for (const [classType, days] of Object.entries(weeklyStats)) {
        for (const [day, values] of Object.entries(days)) {
          const mean = values.reduce((a, b) => a + b, 0) / values.length;
          const sortedValues = [...values].sort((a, b) => a - b);
          const median = sortedValues.length % 2 === 0
            ? (sortedValues[sortedValues.length / 2 - 1] + sortedValues[sortedValues.length / 2]) / 2
            : sortedValues[Math.floor(sortedValues.length / 2)];
          this.statistics.push({
            class: classType,
            day,
            mean,
            median
          });
        }
      }

      // Force chart update
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: categories
          }
        });
      }

      this.loading = false;

    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped></style>
